import { useEffect, useRef, useState } from "react";
import * as styles from "./app.module.scss";
import cn from 'classnames';

const data = (window as any).app.preloadState.pdpImageSlider;
const mediaUrls = data.mediaUrls;

function App() {
    const mainSlider = useRef<HTMLDivElement>(null);
    const thumbnailSlider = useRef<HTMLDivElement>(null);
    const prevButton = useRef<HTMLButtonElement>(null);
    const nextButton = useRef<HTMLButtonElement>(null);
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const main = mainSlider.current!;
        const thumb = thumbnailSlider.current!;

        const checkButtons = () => {
            prevButton.current!.hidden = thumb.scrollLeft === 0;
            nextButton.current!.hidden = thumb.scrollLeft >= (thumb.scrollWidth - thumb.offsetWidth) - 8;
        }
        checkButtons();

        const onMainScroll = () => {
            const index = Math.round(mediaUrls.length * main.scrollLeft / main.scrollWidth);
            setActiveIndex(index);
        };

        const onThumbScroll = () => {
            checkButtons();
        };

        main.addEventListener('scroll', onMainScroll);
        thumb.addEventListener('scroll', onThumbScroll);
        return () => {
            main.removeEventListener('scroll', onMainScroll);
            thumb.removeEventListener('scroll', onThumbScroll);
        }
    }, []);

    const prevImage = () => {
        const thumb = thumbnailSlider.current!;
        thumb.scrollLeft -= thumb.offsetWidth;
    };

    const nextImage = () => {
        const thumb = thumbnailSlider.current!;
        thumb.scrollLeft += thumb.offsetWidth;
    }

    const toThatImage = (index: number) => () => {
        const main = mainSlider.current!;
        main.scrollLeft = index * main.scrollWidth / mediaUrls.length;
    }

    return <div className={styles.sliderParent}>
        <div className={styles.sliderWrapper}>
            <div className={styles.pdpImageSlider} ref={mainSlider}>
                {mediaUrls.map((mediaUrl: string) => (
                    <div key={mediaUrl} className={styles.imageContainer}>
                        <img src={mediaUrl} />
                    </div>
                    ))}
            </div>
        </div>
        <div className={styles.pdpMiniatureImages} ref={thumbnailSlider}>
            {mediaUrls.map((mediaUrl: string, i: number) => (
                <img src={mediaUrl} key={mediaUrl} className={cn(styles.miniImageContainer, i === activeIndex && styles.active)} onClick={toThatImage(i)} />
            ))}
        </div>
        <button className={styles.prevButton} onClick={prevImage} ref={prevButton}></button>
        <button className={styles.nextButton} onClick={nextImage} ref={nextButton}></button>
    </div>
}

export default App;
